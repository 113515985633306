import { CrmEntity } from "../../../api";
import { Field, useFormikContext, ErrorMessage, FieldAttributes } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch, TextField, fieldToSelect, SelectProps } from "formik-mui";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "formik-mui";
import MUISelect, { SelectChangeEvent } from "@mui/material/Select";
import { DatePickerProps, fieldToDatePicker } from "formik-mui-lab";
import Loader from "../Loader/loader";
import { VirtualizedAutoComplete } from "./VirtualizedAutoComplete";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { AutocompleteRenderInputParams } from "formik-mui";
import RadioGroup from "@mui/material/RadioGroup";
import { Box, ButtonProps, FormLabel, Tooltip, Typography } from "@mui/material";
import { IOSSwitch } from "../IOSSwitch/IOSSwitch";
import { DMTheme } from "../../../helpers/DMTheme";
import style from "./form.module.scss";
import React, { ReactElement, ReactNode, useCallback } from "react";
import MuiDatePicker from "@mui/lab/DatePicker";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { StandardButton } from "../StandardButton/StandardButton";
import { Interface } from "readline";
import { Place } from "@mui/icons-material";

export type NoNullParams<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

export type CrmEntities<T> = {
    [P in keyof T as T[P] extends CrmEntity ? P : never]: T[P]
};
export type Objects<T> = {
    [P in keyof T as T[P] extends IdValue ? P : string]: T[P]
};
export type IdValue = { id: string | number | undefined, name: string | undefined }

interface AutoCompleteProps<FormType> {
    formValueName: keyof CrmEntities<Required<NoNullParams<FormType>>>,
    options: CrmEntity[],
    loading: boolean,
}

interface RecordFullValueProps<FormType, T> {
    formValueName: keyof Required<NoNullParams<FormType>>,
    options: T[],
    loading: boolean
    disabled?: boolean,
    showDefault?: boolean,
}

interface GenericFormProps<FormType> {
    formValueName: keyof FormType
    valueRef?: string
}
interface WithField {
    fieldProps?: FieldAttributes<any>
}
interface WithHtmlLabel {
    htmlLabel?: ReactElement<any, any>
}
export class GenericFormElements<FormType extends Record<string, any>> {

    private Labels: Record<keyof FormType, string>;
    private Placeholders = {
        SelectDate: "Vælg dato",
        FillInAny: "Vælg her",
        FillInText: "Udfyld her",
        Search: "Søg her",
    }

    constructor(labels: Record<keyof FormType, string>, placeholderOverwrites?: Record<string, string>) {
        this.Labels = labels;

        if (placeholderOverwrites) {
            this.Placeholders = { ...this.Placeholders, ...placeholderOverwrites };
        }
    }


    public SubmitButton: React.FC<ButtonProps & { loading?: boolean }> = (props) => {
        return <StandardButton className={style.submitBtn} showArrow={false} {...props}>{props.children || "Opdatér"}
        </StandardButton>;
    }

    public BasicSwitch = (props: GenericFormProps<FormType>) => {
        let { formValueName, valueRef } = props;
        return <>
            <FormControlLabel
                control={
                    <Field component={Switch} type="checkbox" name={valueRef ?? formValueName} />
                }
                label={this.Labels[formValueName] ?? formValueName}
            />
            <ErrorMessage name={formValueName as string} />
        </>;
    };

    public BasicSwitchWithHtmlLabel = (props: GenericFormProps<FormType> & WithHtmlLabel) => {
        let { formValueName, valueRef } = props;
        return <>
            <FormControlLabel
                control={
                    <Field component={Switch} type="checkbox" name={valueRef ?? formValueName} />
                }
                label={props.htmlLabel || <div dangerouslySetInnerHTML={{ __html: `<div>${formValueName as string}</div>` }} />}
            />

            <ErrorMessage name={valueRef ?? formValueName as string}>{msg => <div className={style.errorMsg}> {msg} </div>}</ErrorMessage>
        </>;
    };

    public BasicYesNoSwitch = (props: GenericFormProps<FormType> & WithField ) => {
        let { formValueName, valueRef } = props;
        return <>
            <FormControlLabel
                control={
                    <Field component={IOSSwitch}
                        type="checkbox"
                        name={valueRef ?? formValueName}
                        {...props.fieldProps} />
                }
                sx={{ display: "flex", justifyContent: "space-evenly", margin: 0 }}
                labelPlacement="start"
                componentsProps={{ typography: { sx: { width: "50%" } } }}
                label={this.Labels[formValueName] ?? formValueName}
            />
            <ErrorMessage name={formValueName as string} />
        </>;
    };

    public BasicTextInput = (props: GenericFormProps<FormType> & WithField & { type?: string, disabled?: boolean }) => {
        let { formValueName, valueRef } = props;
        return <FormControl fullWidth>
            <Field
                fullWidth
                component={TextField}
                label={this.Labels[formValueName] ?? formValueName}
                name={valueRef ?? formValueName}
                placeholder={this.Placeholders.FillInText}
                type={props.type ?? "text"}
                disabled={props.disabled}
                {...props.fieldProps}
            />
        </FormControl>;
    };

    public BigTextInput = (formValueName: keyof FormType) => {
        return <FormControl fullWidth>
            <Field
                component={TextField}
                multiline
                maxRows={14}
                label={this.Labels[formValueName]}
                name={formValueName}
                placeholder={this.Placeholders.FillInText}
            />

        </FormControl>;
    };

    public SubjectFieldInput = (formValueName: keyof FormType) => {
        return <FormControl fullWidth>
            <Field
                component={TextField}
                label={this.Labels[formValueName]}
                name={formValueName}
                inputProps={{maxLength: 100}}
                placeholder={this.Placeholders.FillInText}
            />

        </FormControl>;
    };

    private DayOnlyDatePicker = (props: DatePickerProps) => {
        const {
            form: { setFieldValue },
            field: { name },
        } = props;
        const onChange = useCallback(
            (date: unknown, keyboardEvent: string | undefined) => {

                if (date instanceof Date && !isNaN(date.valueOf())) {
                    let offsetDate = DanishDateFns.addMinutes(date, date.getTimezoneOffset() * -1);
                    setFieldValue(name, offsetDate);
                }
            },
            [setFieldValue, name]
        );
        return <MuiDatePicker {...fieldToDatePicker(props)} onChange={onChange} />;
    }

  public BasicDateInput = (props: GenericFormProps<FormType> & WithField & { disabled?: boolean } ) => {
      let { formValueName, valueRef } = props;
      return <FormControl fullWidth>
          <Field
              component={this.DayOnlyDatePicker}
              name={formValueName}
              label={this.Labels[formValueName] ?? formValueName}
              placeholder={this.Placeholders.SelectDate}
              disabled={props.disabled}
              {...props.fieldProps}
          />
      </FormControl>;
  };

    public BasicYesNoRadioButtons = (formValueName: keyof FormType, valueRef?: string) => {
        return <FormControl className={style.yesNoControl}>
            <FormLabel htmlFor={formValueName as string}> {this.Labels[formValueName] ?? formValueName}</FormLabel>
            <RadioGroup
                row
                name={valueRef ?? formValueName as string}
            >
                <label>
                    <Field type="radio" name={valueRef ?? formValueName} value={"Yes"} />
                    <span> Ja</span>
                </label>

                <label>
                    <Field type="radio" name={valueRef ?? formValueName} value={"No"} />
                    <span> Nej</span>
                </label>
            </RadioGroup>
            <ErrorMessage name={valueRef ?? formValueName as string}>{msg => <div className={style.errorMsg}> {msg} </div>}</ErrorMessage>
        </FormControl>;
    }

    // Turn enum into array
    private ToArray: (enumOptions: Record<string, string>) => string[] = (enumOptions: Record<string, string>) => {
        return Object.keys(enumOptions).map(key => enumOptions[key]);
    };

    public EnumDropDown = (formValueName: keyof FormType, enumOptions: Record<string, string>, multiSelect: boolean = false, showDefault: boolean = true, disabled?: boolean, loading?: boolean) => {
        let options = this.ToArray(enumOptions).sort();
        return <FormControl fullWidth>

            <Field
                component={Select}
                disabled={disabled}
                type={"text"}
                multiple={multiSelect}
                name={formValueName}
                defaultValue={""}
                label={this.Labels[formValueName] ?? formValueName}
                inputProps={{ name: formValueName, id: formValueName }}
            >
                {options.map((enumMember, index) => {
                    return <MenuItem sx={{
                        fontFamily: "AvenirNext",
                        fontWeight: 400,
                        fontStyle: "normal",
                    }}
                    key={index} value={enumMember}>{enumMember}
                    </MenuItem>;
                })}
                <MenuItem key="default" sx={{ display: "none" }} value={""}>
                </MenuItem>
            </Field>
        </FormControl>;
    };

    public StringDropDown = (formValueName: keyof FormType, options: string[], valueRef?: string) => {
        const sortedOptions = options.sort();
        return <FormControl fullWidth>
            <InputLabel shrink={true} htmlFor={formValueName as string}>
                <Box sx={{ background: DMTheme.palette.background.default }}>
                    {this.Labels[formValueName] ?? formValueName}
                </Box>
            </InputLabel>
            <Field
                component={Select}
                type={"text"}
                name={valueRef ?? formValueName}
                inputProps={{ name: valueRef ?? formValueName, id: valueRef ?? formValueName }}
            >
                {sortedOptions.map((enumMember, index) => {
                    return <MenuItem sx={{
                        fontFamily: "AvenirNext",
                        fontWeight: 400,
                        fontStyle: "normal",
                    }} key={`${formValueName.toString()} ${index}`} value={enumMember}>
                        {enumMember}
                    </MenuItem>;
                })}
            </Field>
        </FormControl>;
    };

    public StringMultiSelectDropDown = (formValueName: keyof FormType, options: string[], valueRef?: string) => {
        const sortedOptions = options.sort();
        return <FormControl fullWidth>
            <InputLabel shrink={true} htmlFor={formValueName as string} sx={{ background: DMTheme.palette.background.default }}>
                {this.Labels[formValueName] ?? formValueName}
            </InputLabel>
            <Field
                component={Select}
                name={valueRef ?? formValueName}
                multiple={true}
                inputProps={{ name: valueRef ?? formValueName, id: valueRef ?? formValueName }}
            >
                {sortedOptions.map((enumMember, index) => {
                    return <MenuItem sx={{
                        fontFamily: "AvenirNext",
                        fontWeight: 400,
                        fontStyle: "normal",
                    }}
                    key={index} value={enumMember}>{enumMember}
                    </MenuItem>;
                })}
            </Field>
        </FormControl>;
    };

    public IdNameDropdown = (formValueName: keyof Objects<Required<NoNullParams<FormType>>>, options: { id: string | number | undefined, name?: string | null }[], loading: boolean, disabled?: boolean) => {
        if (typeof formValueName != "string") {
            return "";
        }
        return <FormControl fullWidth>
            <Field
                fullwidth
                label={<Box sx={{ background: DMTheme.palette.background.default }}>
                    {this.Labels[formValueName]}
                </Box>}
                component={Select}
                type={"text"}
                name={`${formValueName}.id`}
                disabled={disabled}
                inputProps={{ name: `${formValueName}.name`, id: `${formValueName}.id` }}
            >
                {loading ? <Loader /> : options.map((entity, index) => {
                    return <MenuItem sx={{
                        fontFamily: "AvenirNext",
                        fontWeight: 400,
                        fontStyle: "normal",
                    }}
                    key={entity.id} value={entity.id}>{entity.name}
                    </MenuItem>;
                })}
            </Field>
        </FormControl>;
    };

    public RecordDropDown = (formValueName: keyof CrmEntities<Required<NoNullParams<FormType>>>, options: CrmEntity[], loading: boolean, showDefault: boolean = false, disabled = false, showTooltip = false) => {
        if (typeof formValueName != "string") {
            return "";
        }
        return <FormControl fullWidth>
            <Field
                disabled={disabled}
                label={<Box sx={{ background: DMTheme.palette.background.default }}>
                    {this.Labels[formValueName]}
                </Box>}
                component={Select}
                type={"text"}
                name={`${formValueName}.id`}
                inputProps={{ name: `${formValueName}.primaryName`, id: `${formValueName}.id` }}
                defaultValue={""}
            >
                {showDefault && <MenuItem
                    key={"default"} disabled value={"Vælg..."}></MenuItem>}
                {loading ? <Loader /> : options.map((entity, index) => {
                    return <MenuItem sx={{
                        fontFamily: "AvenirNext",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontSize: "17.6px",
                    }}
                    key={entity.id} value={entity.id}>{entity.primaryName}
                    </MenuItem>;
                })}
            </Field>
        </FormControl>;
    };

    private MUIObjectSelect = (props: SelectProps) => {
        const {
            form: { setFieldValue },
            field: { name },
        } = props;
        const onChange = useCallback(
            (event: SelectChangeEvent<unknown>, child: ReactNode) => {
                if (typeof event.target.value === "string") {
                    let parsedObj = JSON.parse(event.target.value);
                    setFieldValue(name, parsedObj, false);
                }
            },
            [setFieldValue, name]
        );
        const onClose = useCallback(
            (event: React.SyntheticEvent<Element, Event>) => {
                event.stopPropagation();
            },
            [setFieldValue, name]
        );
        return <MUISelect
            {...fieldToSelect(props)}
            onChange={onChange}
            onClose={onClose}

        />;
    }

    public RecordDropDownFullValue = <T extends { id: string, primaryName: string },>(props: RecordFullValueProps<FormType, T>) => {
        const { formValueName, options } = props;
        if (typeof formValueName != "string") {
            throw new TypeError("Form value was not a string");
        }
        const { values } = useFormikContext<FormType>();

        return <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
                <Box sx={{ background: DMTheme.palette.background.default }}>
                    {this.Labels[formValueName]}
                </Box>
            </InputLabel>
            <Field
                label={<Box sx={{ background: DMTheme.palette.background.default }}>
                    {this.Labels[formValueName]}
                </Box>}
                component={this.MUIObjectSelect}
                type={"text"}
                name={formValueName}
                value={JSON.stringify(values[formValueName])}
                disabled={props.disabled}
                defaultValue={""}
            >
                {options.map((entity: T, index: number) => {
                    return <MenuItem sx={{
                        fontFamily: "AvenirNext",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontSize: "17.6px",
                    }}
                    key={entity.id} value={JSON.stringify(entity)}>{entity.primaryName}
                    </MenuItem>;
                })}
            </Field>
        </FormControl>;
    };

    public RecordAutoComplete = (props: AutoCompleteProps<FormType> & {disabled?: boolean}) => {

        const { formValueName, options, loading, disabled } = props;
        if (typeof formValueName != "string") {
            throw new TypeError("Form value was not a string");
        }
        const { values, errors, touched, setFieldValue } = useFormikContext<FormType>();
        return <FormControl fullWidth >
            <Field
                disabled={disabled}
                options={[values[formValueName], ...options]}
                getOptionLabel={(option: CrmEntity) => option?.primaryName ?? ""}
                isOptionEqualToValue={(option: CrmEntity, value: CrmEntity) => option?.id == value?.id}
                component={VirtualizedAutoComplete}
                loading={loading}
                value={values[formValueName]}
                onChange={(e: any, value: CrmEntity) => setFieldValue(formValueName, value)}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                renderOption={(props: any, option: CrmEntity) =>{
                    const menuItem=<MenuItem sx={{
                        fontFamily: "AvenirNext",
                        fontWeight: 400,
                        fontStyle: "normal",
                    }}> {option?.primaryName} </MenuItem>;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    return [props, <Tooltip key={option?.id} title={option?.primaryName} disableFocusListener={true}
                        enterDelay={500}>{menuItem}</Tooltip>];
                }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                    <MuiTextField
                        {...params}
                        label={<Box sx={{ background: DMTheme.palette.background.default }}>
                            {this.Labels[formValueName]}
                        </Box>}
                        name={formValueName}
                        error={touched[formValueName] && !!errors[formValueName]}
                        helperText={touched[formValueName] && errors[formValueName]}
                        variant="outlined"

                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <Loader /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </FormControl>;
    }

}
