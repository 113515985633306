/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ApiError, AgreementGroup, MetadataService, PaymentGroupService, ContactsService, Available } from "../../../api";
import { Form, Formik } from "formik";
import { AvailableSchema } from "./AvailableSchema";
import { AvailableFormValues } from "./AvailableSchema";
import { useEffect, useState } from "react";
import { ApiErrorBody } from "../../../models/apiError";
import globalStyle from "../../../global.scss";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { Button, Stack } from "@mui/material";
import { AvailableFormElement } from "../../../models/umbracoElement";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { ConfirmMessageBox } from "../ConfirmMessageBox/ConfirmMessageBox";
import { profilPageLink } from "../../../constants/common-constants";

export type IAvailableFormProps = {
    data: string
}

export type IAvailableFormState = {
}

const AvailableFormLabels: (umbracoSettings: AvailableFormElement) => Record<keyof AvailableFormValues, string> = (umbracoSettings: AvailableFormElement) => {
    return {
        "startDate": umbracoSettings.startDatoLabel ?? "Start dato",
        "availableType": "Type",
        "isPreviousKIMember": "",
    };
};


export const AvailableForm = (props: IAvailableFormProps) => {
    const umbracoSettings: AvailableFormElement = JSON.parse(props.data);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [availableTypes, setLeaveTypes] = useState<AgreementGroup[]>([]);
    const [readonly, setReadOnly] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [defaultValues, setDefaultValues] = useState<AvailableFormValues>({
        startDate: new Date(),
        availableType: undefined,
        isPreviousKIMember: false,
    });

    useEffect(() => {
        void GetCurrentInformation();
        void getAvailableLeaveTypes();
    }, []);

    const getAvailableLeaveTypes = async () => {
        try {
            setLeaveTypes(await MetadataService.getAvailableLeaveGroup());
        } catch (e) {
            console.error(e);
            setErrorMsg("Der er sket en fejl ved at hente orloy type");
        }
    };

    const GetCurrentInformation = async () => {
        setIsLoading(true);
        let currentInformation: Available | undefined = undefined;
        try {
            currentInformation = await PaymentGroupService.getCurrentAvailabilityInformation();
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.log("Brugeren var ikke ledig");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setReadOnly(true);
                    setErrorMsg(errorInfo.title);
                }
            }
        } finally {
            const isPreviousKIMember = await getIsPreviousKIMember();

            let newDefaults: AvailableFormValues = currentInformation != undefined ?
                {
                    startDate: currentInformation ? new Date(currentInformation.startDate) : new Date(),
                    availableType: currentInformation.availableType || undefined,
                    isPreviousKIMember: defaultValues.isPreviousKIMember,
                } : {...defaultValues, isPreviousKIMember: isPreviousKIMember};

            setDefaultValues(newDefaults);
            setIsLoading(false);
        }
    };

    const getIsPreviousKIMember = async () => {
        try {
            return await ContactsService.getIsPreviousKiMember();
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const changePaymentGroup = async (formData: AvailableFormValues) => {
        try {
            void await PaymentGroupService.setToLeaveOrAvailable({
                ...formData,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                availableType: formData.availableType,
                startDate: formData.startDate.toISOString(),
            });
            setShowConfirm(true);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    setErrorMsg("Det ønskede skifte er ikke muligt fra din nuværende kontingent gruppe");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    console.error(errorInfo);
                    setErrorMsg(errorInfo.title);
                } else if (e.status == 500) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    console.error(errorInfo);
                    setErrorMsg("Der skete en uventet fejl, din kontingent gruppe er ikke blevet ændret");
                }
            }
        }
    };

    const onCloseConfirmMessage=()=>{
        setShowConfirm(false);
        window.location.href=profilPageLink;
    };

    const FormElements = new GenericFormElements<AvailableFormValues>(AvailableFormLabels(umbracoSettings));

    useEffect(() => {
        console.log(defaultValues);

    }, [defaultValues]);

    let form = <Formik
        initialValues={defaultValues}
        validationSchema={AvailableSchema}
        validate={(x) => console.log(x)}
        onSubmit={async (values, { setSubmitting }) => {
            console.log(values);
            await changePaymentGroup(values);
            setSubmitting(false);
        }}
        enableReinitialize={true}
    >
        {({ values,
            handleSubmit,
            isSubmitting,
            errors,
        }) => (
            <Form className={formStyles.dmForm} onSubmit={handleSubmit} >
                <FormElements.BasicDateInput formValueName="startDate" />
                <FormElements.RecordDropDownFullValue
                    formValueName="availableType"
                    options={availableTypes.map((at => { return { id: at.priceGroup, primaryName: at.displayName, ...at }; }))}
                    loading={availableTypes.length === 0} />
                <FormElements.SubmitButton className={formStyles.submitBtn} type="submit" loading={isSubmitting}
                    disabled={readonly || isSubmitting || isLoading} />
                {values.startDate && showConfirm &&
                 <ConfirmMessageBox
                     fromDate={values.startDate}
                     onClose={onCloseConfirmMessage} />}
                <MessageDiv type={MessageType.error} msg={errorMsg} />
            </Form>
        )
        }
    </Formik>;
    return <Stack direction="column" justifyItems="flex-start" alignItems="grow">
        <h2>{umbracoSettings.text}</h2>
        {umbracoSettings.contentText && SantizedRichHtml(umbracoSettings.contentText)}
        {form}
    </Stack>;
};