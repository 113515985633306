/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CrmEntity } from './CrmEntity';

export type MainEducationUpdate = {
    name?: string | null;
    course?: CrmEntity;
    graduateDate?: string | null;
    expectedGraduateDate?: string | null;
    id?: string | null;
    placeOfStudy?: CrmEntity;
    type?: CrmEntity;
    educationField?: CrmEntity;
    levelOfEducation?: MainEducationUpdate.levelOfEducation | null;
    disced?: CrmEntity;
    isStudent?: boolean;
    furtherEducation?: string | null;
}

export namespace MainEducationUpdate {

    export enum levelOfEducation {
        BACHELOR = 'Bachelor',
        PROFESSIONSBACHELOR = 'Professionsbachelor',
        KANDIDAT = 'Kandidat',
        PHD = 'Phd',
    }


}