/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useMemo, useState } from "react";
import { MetadataService } from "../../../api/services/MetadataService";
import { ApiError, CrmEntity, PaymentGroupService, MainEmployment, ContactsService } from "../../../api";
import { Form, Formik } from "formik";
import { FreelanceOrIndependentFormValues, FreelanceSchema, SelfEmployedSchema } from "./FreelanceIndependentSchema";
import { FreelanceFormElement } from "../../../models/umbracoElement";
import { ApiErrorBody } from "../../../models/apiError";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import globalStyle from "../../../global.scss";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { Box, FormControl, FormGroup, Select, Stack } from "@mui/material";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { ConfirmMessageBox } from "../ConfirmMessageBox/ConfirmMessageBox";
import Loader from "../../_GenericElements/Loader/loader";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { FullTime, PartTime, profilPageLink } from "../../../constants/common-constants";

export type IChangeToWorkingFormProps = {
    data: string
}
const FreelanceFormLabels: (elementConfig: FreelanceFormElement) => Record<keyof FreelanceOrIndependentFormValues, string> = (elementConfig) => {
    return {
        "startDate": elementConfig.startDatoLabel || "Start dato",
        "jobPosition": elementConfig.stillingLabel || "Stilling",
        "hasFullTimeEmployment": elementConfig.arbejderFuldtidLabel || "Arbejder fuldtid",
        "newPlaceOfWork": elementConfig.ansaettelsesstedLabel || "Ansættelsessted",
        "employmentType": elementConfig.ansaettelsestypeLabel || "Ansættelsestype",
        "placeOfWorkNotinList": elementConfig.tilfojAnsaettelsesstedLabel || "Tilføj Ansættelsessted",
        "placeOfEmploymentName": elementConfig.virksomhedsnavnLabel || "Virksomhedsnavn",
        "companyAddress": elementConfig.virksomhedsadresseLabel || "Virksomhedsadresse",
        "cvr": elementConfig.cVRLabel || "CVR",
        "isFreelancer": elementConfig.selvstaendigLabel || "Selvstændig",
        "vipTap": elementConfig.vipTapLabel ||"VIP/TAP",
        "workTime": elementConfig.workTimeLabel || "Fuldtid/deltid",
        "isPreviousKIMember": "IsPreviousKIMember",
        "workArea": "Arbejdsområde",
    };
};
/** Form to change to Freelance or self employeed */
export const ChangeToFreelanceForm = (props: IChangeToWorkingFormProps) => {
    let parsedData: FreelanceFormElement = JSON.parse(props.data);
    const [loading, setLoading] = useState(true);
    const [readonly, setReadOnly] = useState(false);
    const [workplaces, setWorkPlaces] = useState<NonNullable<CrmEntity[]>>([]);
    const [jobPositions, setjobPositions] = useState<NonNullable<CrmEntity[]>>([]);
    const [workAreas, setWorkAreas] = useState<NonNullable<CrmEntity[]>>([]);
    const [employmentTypes, setemploymentTypes] = useState<NonNullable<CrmEntity[]>>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [freelancer, setFreelancer] = useState<boolean>();
    const [showConfirm, setShowConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [defaultValues, setDefaultValues] = React.useState<FreelanceOrIndependentFormValues>({
        startDate: new Date(),
        hasFullTimeEmployment: false,
        placeOfWorkNotinList: true,
        placeOfEmploymentName: "",
        companyAddress: "",
        cvr: "",
        jobPosition: { id: "", primaryName: "" },
        employmentType: { id: "", primaryName: "" },
        newPlaceOfWork: { id: "", primaryName: "" },
        isFreelancer: false,
        workTime: "",
        isPreviousKIMember: false,
        workArea: { id: "", primaryName: "" },
    });

    const GetCurrentInformation = async () => {
        setIsLoading(true);
        let currentInformation: MainEmployment | undefined = undefined;
        try {
            currentInformation = await PaymentGroupService.getCurrentWorkInformation();
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.log("Brugeren var ikke i arbejde");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                    setReadOnly(true);
                }
            }
        } finally {
            const isPreviousKIMember = await getIsPreviousKIMember();

            const newDefaults: FreelanceOrIndependentFormValues = currentInformation != undefined ?
                {
                    startDate: new Date(),
                    workTime: currentInformation.hasFullTimeEmployment ? FullTime : PartTime,
                    hasFullTimeEmployment: currentInformation.hasFullTimeEmployment ?? false,
                    placeOfWorkNotinList: currentInformation.placeOfWorkNotinList ?? false,
                    isFreelancer: currentInformation.isFreelancer ?? false,
                    placeOfEmploymentName: currentInformation.placeOfEmploymentName ?? undefined,
                    companyAddress: currentInformation.companyAddress ?? undefined,
                    cvr: currentInformation.cvr ?? undefined,
                    jobPosition: currentInformation.jobPosition ?? { id: "", primaryName: "" },
                    employmentType: { id: "", primaryName: "" },
                    newPlaceOfWork: currentInformation.newPlaceOfWork ?? undefined,
                    isPreviousKIMember: isPreviousKIMember,
                    workArea: { id: "", primaryName: "" },
                } : {
                    ...defaultValues,
                    isPreviousKIMember: isPreviousKIMember,
                };
            setDefaultValues(newDefaults);
            setIsLoading(false);
        }
    };

    const getIsPreviousKIMember = async () => {
        try {
            return await ContactsService.getIsPreviousKiMember();
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const GetOptions = async () => {
        void await Promise.all(
            [
                MetadataService.getAllWorkPlaces().then(wp => setWorkPlaces(wp)),
                MetadataService.getAllJobPositions().then(jp => setjobPositions(jp)),
                MetadataService.getFreelanceIndependentEmploymentTypes().then(et => setemploymentTypes(et)),
                MetadataService.getAllWorkAreas().then(wa => setWorkAreas(wa)),
            ]
        );
    };

    const changePaymentGroup = async (formData: FreelanceOrIndependentFormValues) => {

        let workingData: MainEmployment = {
            ...formData,
            isFreelancer:freelancer??false,
            hasFullTimeEmployment: formData.workTime === FullTime,
            startDate: formData.startDate.toISOString(),

        };

        try {
            if (freelancer) {
                workingData = {
                    ...workingData,
                    newPlaceOfWork: undefined,
                    companyAddress: undefined,
                    placeOfEmploymentName: undefined,
                    cvr: undefined,

                };

            } else {
                workingData = {
                    ...workingData,
                    newPlaceOfWork:  undefined,
                    companyAddress:  formData.companyAddress,
                    placeOfEmploymentName: formData.placeOfEmploymentName,
                    cvr:  formData.cvr,
                };

            }

            void await PaymentGroupService.setToFreelanceIndipendent(workingData);
            setShowConfirm(true);
        } catch (e) {

            if (e instanceof ApiError) {
                if (e.status == 422) {
                    setErrorMsg("Det ønskede skifte er ikke muligt fra din nuværende kontingent gruppe");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    useEffect(() => {
        (async () => {
            void await GetCurrentInformation();
            void await GetOptions();
        })().finally(() => setLoading(false));
    }, []);

    const FormElements = new GenericFormElements<FreelanceOrIndependentFormValues>(FreelanceFormLabels(parsedData));
    const worktimeDropdownOptions = [FullTime, PartTime];
    const workingtimeDropdown = FormElements.StringDropDown("workTime", worktimeDropdownOptions);
    const addressField = <FormElements.BasicTextInput formValueName="companyAddress" />;
    const workplaceNameField = <FormElements.BasicTextInput formValueName="placeOfEmploymentName"/>;
    const cvrField = <FormElements.BasicTextInput formValueName="cvr"/>;

    let dateField = <FormElements.BasicDateInput formValueName="startDate" />;

    const AdditionalRequiredInfoFields = (values: FreelanceOrIndependentFormValues) => {
        if (freelancer == false) {
            return <>
                {workplaceNameField}
                {addressField}
                {cvrField}
            </>;
        } else {
            return;
        }
    };

    const onCloseConfirmMessage=()=>{
        setShowConfirm(false);
        window.location.href=profilPageLink;
    };

    const form = useMemo(() => {
        return <Formik
            initialValues={defaultValues}
            validationSchema={freelancer ? FreelanceSchema : SelfEmployedSchema}
            onSubmit={async (values, { setSubmitting }) => {
                await changePaymentGroup(values);
                setSubmitting(false);
            }}
            enableReinitialize={true}
            validate={(values) => {
                if (values.employmentType?.id) {
                    const chosentype = employmentTypes.find(x => x.id === values.employmentType.id);
                    setFreelancer(chosentype?.primaryName === "Freelance");
                }
            }}
        >
            {({ values,
                isSubmitting,
                handleSubmit,
                isValid,
                errors }) =>
                <Form className={formStyles.dmForm} onSubmit={handleSubmit}>
                    {FormElements.RecordDropDown("employmentType", employmentTypes, employmentTypes.length == 0 && loading, false, isSubmitting)}

                    {freelancer !== undefined && <>
                        {dateField}
                        {workingtimeDropdown}
                        {FormElements.RecordDropDown("jobPosition", jobPositions, jobPositions.length == 0 && loading, false, isSubmitting)}
                        {FormElements.RecordDropDown("workArea", workAreas, workAreas.length == 0 && loading, false, isSubmitting)}
                        {AdditionalRequiredInfoFields(values)}
                        <FormElements.SubmitButton loading={isSubmitting} type="submit" disabled={readonly || isSubmitting || isLoading} />
                    </>}

                    {values.startDate && showConfirm &&
                    <ConfirmMessageBox fromDate={values.startDate} onClose={onCloseConfirmMessage} />}
                    <MessageDiv type={MessageType.error} msg={errorMsg} />
                </Form>
            }
        </Formik>;
    }, [freelancer, employmentTypes, showConfirm, workAreas]);
    return <Stack direction="column" justifyItems="flex-start" alignItems="grow">
        <h2>{parsedData.text}</h2>
        {parsedData.contentText && SantizedRichHtml(parsedData.contentText)}
        {loading ? <Loader /> : form}
    </Stack>;
};