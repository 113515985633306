/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { ContactRead, ContactsService, CrmEntity, MetadataService } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { SectorInterestElement } from "../../../models/umbracoElement";
import React from "react";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { isMobile } from "../../../helpers/MobileHelper";
import { DescribedCheckbox } from "../DescribedCheckbox/described-checkbox";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { missingFieldsReplaceToken } from "../../../constants/common-constants";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import * as style from "./sector-interests-form.module.scss";

export interface SectorInterestsFormProps {
    data: string;
}
type SectorOptionInfo = {
    sectorSelectOptions: (CrmEntity & { isSelected: boolean })[],
    notChangeableSectors: string[],
    canChange: boolean
}
export const SectorInterestsForm = (props: SectorInterestsFormProps) => {
    const [umbracoSettings] = React.useState<SectorInterestElement>(props.data ? JSON.parse(props.data) : {});
    const [isSubmitting, setSubmitting] = useState(false);
    const [primarySectorId, setPrimarySectorId] = useState<string>();
    const [isLoading, setLoading] = useState(true);
    const [defaultValue, setDefaultValue] = useState<{ [k: string]: boolean }>();
    const [sectorOptionInfo, setSectorOptionInfo] = React.useState<SectorOptionInfo>();
    useEffect(() => {
        void getCurrentData();
    }, []);

    const getCurrentData = async () => {
        setLoading(true);
        const sectorsInfo = await ContactsService.getSectors();
        const contact = await ContactsService.getContact();
        setPrimarySectorId(contact.sector?.id);
        const selections = (sectorsInfo.sectorInterestOptions || []).map(x => { return { ...x, isSelected: contact?.sectorInterests?.includes(x.id) || x.id === contact.sector?.id }; });
        const optionInfo:
            SectorOptionInfo = {
                sectorSelectOptions: selections,
                canChange: (contact.sector?.id ? !sectorsInfo.notAllowedChangePrimarySectors?.includes(contact.sector?.id) : false),
                notChangeableSectors: sectorsInfo.notAddibleSectors || [],
            };
        let defaults: any = {};
        selections.forEach(x => defaults = { ...defaults, [x.id]: x.isSelected });
        console.log(defaults);
        setDefaultValue(defaults);
        setSectorOptionInfo(optionInfo);
        setLoading(false);
    };

    const updateSelectedSectors = async (newlist: string[]) => {
        await ContactsService.updateSectorInterests(newlist);
    };


    const newsSubscriptionForm = (
        <Formik
            initialValues={defaultValue!}
            onSubmit={async (values) => {
                setSubmitting(true);
                const newlist = Object.entries(values)
                    .filter(([key, child]: [string, boolean]) => child === true && key !== primarySectorId)
                    .map(([key, _]: [string, boolean]) => key);
                await updateSelectedSectors(newlist);
                setSubmitting(false);
            }}
            enableReinitialize={true}
        >
            {({ errors, values }) =>
                <Form style={{ textAlign: isMobile ? "center" : "inherit" }}>
                    {sectorOptionInfo?.sectorSelectOptions.map((s, i) =>
                        <DescribedCheckbox
                            key={i}
                            name={s.id}
                            disabled={!sectorOptionInfo.canChange || s.id === primarySectorId || sectorOptionInfo.notChangeableSectors.includes(s.id)}
                            label={s.primaryName + (s.id === primarySectorId ? " (primær, kan ikke vælges)" : "")}
                            description={""} />)}
                    {sectorOptionInfo?.canChange && <StandardButton type="submit" loading={isSubmitting} disabled={isSubmitting}
                        showArrow={false}>
                        {umbracoSettings.submitButtonLabel || "Opdatér"}
                    </StandardButton>}
                </Form>}
        </Formik>);

    return (
        <><h2>{umbracoSettings.text || ""}</h2>
            {isLoading ?
                <Loader /> : <>
                    {umbracoSettings.contentText && SantizedRichHtml(umbracoSettings.contentText)}
                    {defaultValue && newsSubscriptionForm}
                    {!primarySectorId &&
                        <MessageDiv msg={umbracoSettings.missingPrimarySectorText || "Du mangler primærsektor. Du kan ikke vælge interessesektor."} type={MessageType.error} />
                    }
                    {<> {umbracoSettings.contactInformationLink?.url &&
                            <StandardButton className={style.linkbutton} href={umbracoSettings.contactInformationLink.url}>Ret kontaktoplysninger</StandardButton>}
                    </>}
                </>}
        </>
    );
};