import React, { useEffect, useState } from "react";
import { ApiError, PaymentGroupService, ChangeWorkTime, MainEmployment, ContactsService } from "../../../api";
import { Form, Formik } from "formik";
import { ChangeWorkTimeFormValues, ChangeWorkTimeSchema } from "./WorkTimeSchema";
import { WorkTimeFormElement } from "../../../models/umbracoElement";
import { ApiErrorBody } from "../../../models/apiError";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { Stack } from "@mui/material";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { FullTime, PartTime, profilPageLink } from "../../../constants/common-constants";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { ConfirmMessageBox } from "../ConfirmMessageBox/ConfirmMessageBox";

export type IChangeToWorkTimeFormProps = {
    data: string
}


const ChangeWorkTimeFormLabels: (elementConfig: WorkTimeFormElement) => Record<keyof ChangeWorkTimeFormValues, string> = (elementConfig) => {
    return {
        "startDate": elementConfig.startDateLabel || "Start dato",
        "workTime": elementConfig.workTimeLabel || "Fuldtid/deltid",
        "isPreviousKIMember": "",
    };
};

export const WorkTimeForm = (props: IChangeToWorkTimeFormProps) => {
    let parsedData: WorkTimeFormElement = JSON.parse(props.data || "{}");
    const [isLoading, setIsLoading] = useState(false);
    const [readonly, setReadOnly] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [showConfirm, setShowConfirm] = useState(false);

    const [defaultValues, setDefaultValues] = useState<ChangeWorkTimeFormValues>({
        startDate: new Date(),
        workTime: "",
        isPreviousKIMember: false,
    });

    const GetCurrentInformation = async () => {
        setIsLoading(true);
        let currentInformation: MainEmployment | undefined = undefined;
        try {
            currentInformation = await PaymentGroupService.getCurrentWorkInformation();
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.log("Brugeren var ikke i arbejde");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                    setReadOnly(true);
                }
            }
        } finally {
            const isPreviousKIMember = await getIsPreviousKIMember();
            let newDefaults: ChangeWorkTimeFormValues = currentInformation != undefined ?
                {
                    startDate: new Date(),
                    workTime: currentInformation.hasFullTimeEmployment ? FullTime : PartTime,
                    isPreviousKIMember: isPreviousKIMember,
                } :
                {
                    ...defaultValues,
                    isPreviousKIMember: isPreviousKIMember,
                };

            setDefaultValues(newDefaults);
            setIsLoading(false);
        }
    };

    const getIsPreviousKIMember = async () => {
        try {
            return await ContactsService.getIsPreviousKiMember();
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const changePaymentGroup = async (formData: ChangeWorkTimeFormValues) => {
        const newWorkTime: ChangeWorkTime = {
            hasFullTimeEmployment: formData.workTime === FullTime,
            startDate: formData.startDate.toISOString(),
        };
        try {
            void await PaymentGroupService.changeWorkTime(newWorkTime);
            setShowConfirm(true);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    setErrorMsg("Det ønskede skifte er ikke muligt fra din nuværende kontingent gruppe");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    useEffect(() => {
        void (async () => { await GetCurrentInformation(); })();
    }, []);

    const worktimeDropdownOptions = [FullTime, PartTime];
    const FormElements = new GenericFormElements<ChangeWorkTimeFormValues>(ChangeWorkTimeFormLabels(parsedData));
    const workingtimeDropdown = FormElements.StringDropDown("workTime", worktimeDropdownOptions);
    const dateField = <FormElements.BasicDateInput formValueName="startDate" />;
    const onCloseConfirmMessage=()=>{
        setShowConfirm(false);
        window.location.href=profilPageLink;
    };
    const form = <Formik
        initialValues={defaultValues}
        validationSchema={ChangeWorkTimeSchema}
        onSubmit={async (values, { setSubmitting }) => {
            await changePaymentGroup(values);
            setSubmitting(false);
        }}
        enableReinitialize={true}
    >
        {({ values,
            isSubmitting,
            handleSubmit }) =>

            <Form className={formStyles.dmForm} onSubmit={handleSubmit}>
                {dateField}
                {workingtimeDropdown}
                <FormElements.SubmitButton loading={isSubmitting} type="submit" disabled={readonly || isSubmitting || isLoading} />
                {values.startDate && showConfirm && <ConfirmMessageBox fromDate={values.startDate} onClose={onCloseConfirmMessage} />}
                <MessageDiv type={MessageType.error} msg={errorMsg} />
            </Form>
        }
    </Formik>;
    return <Stack direction="column" justifyItems="flex-start" alignItems="grow">
        <h2>{parsedData.text}</h2>
        {parsedData.contentText && SantizedRichHtml(parsedData.contentText)}
        {form}
    </Stack>;
};