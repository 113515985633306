/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Benefit } from '../models/Benefit';
import type { Consent } from '../models/Consent';
import type { ContactRead } from '../models/ContactRead';
import type { ContactUpdate } from '../models/ContactUpdate';
import type { CrmEntity } from '../models/CrmEntity';
import type { Group } from '../models/Group';
import type { MainEducationUpdate } from '../models/MainEducationUpdate';
import type { MemberConsent } from '../models/MemberConsent';
import type { Network } from '../models/Network';
import type { NewsSubcription } from '../models/NewsSubcription';
import type { ParentalLeave } from '../models/ParentalLeave';
import type { PillarMembership } from '../models/PillarMembership';
import type { PillarTargetGroups } from '../models/PillarTargetGroups';
import type { Sector } from '../models/Sector';
import type { SectorInterest } from '../models/SectorInterest';
import type { WorkDetailsUpdate } from '../models/WorkDetailsUpdate';
import { request as __request } from '../core/request';

export class ContactsService {

    /**
     * @returns ContactRead Success
     * @throws ApiError
     */
    public static async getContact(): Promise<ContactRead> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/me`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async updateContact(
requestBody?: ContactUpdate,
): Promise<string> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/contacts`,
            body: requestBody,
            errors: {
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async createContact(
requestBody?: ContactUpdate,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/contacts`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async createNewMaineducation(
requestBody?: MainEducationUpdate,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/contacts/main_education`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MainEducationUpdate Success
     * @throws ApiError
     */
    public static async getMainEducation(): Promise<MainEducationUpdate> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/main_education`,
        });
        return result.body;
    }

    /**
     * @returns WorkDetailsUpdate Success
     * @throws ApiError
     */
    public static async getMainEmployment(): Promise<WorkDetailsUpdate> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/main_employment`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async updateMainEmployment(
requestBody?: WorkDetailsUpdate,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/contacts/main_employment`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async updateParentalLeave(
requestBody?: ParentalLeave,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/contacts/parental_leave`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns SectorInterest Success
     * @throws ApiError
     */
    public static async getSectors(): Promise<SectorInterest> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/sectors`,
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getSelectableMasterDataSectors(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/masterdatasectors`,
        });
        return result.body;
    }

    /**
     * @returns PillarTargetGroups Success
     * @throws ApiError
     */
    public static async getTargetPillarGroups(): Promise<PillarTargetGroups> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/target_pillar_groups`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns NewsSubcription Success
     * @throws ApiError
     */
    public static async getNewsSubscriptions(): Promise<NewsSubcription> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/news_subscriptions_info`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async updateNewsSubscriptions(
requestBody?: NewsSubcription,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/contacts/news_subscriptions`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async updatePillarMemberships(
requestBody?: Array<PillarMembership>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/contacts/pillar_memberships`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async updateSectorInterests(
requestBody?: Array<string>,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/contacts/sectorinterests`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async updateMasterdataSector(
requestBody?: Sector,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/contacts/masterdatasector`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns Benefit Success
     * @throws ApiError
     */
    public static async getBenefits(): Promise<Array<Benefit>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/benetfits`,
        });
        return result.body;
    }

    /**
     * @returns Network Success
     * @throws ApiError
     */
    public static async getNetworks(): Promise<Array<Network>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/networks`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns Group Success
     * @throws ApiError
     */
    public static async getPoliticalGroups(): Promise<Array<Group>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/get_political_groups`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static async isViPorTap(): Promise<boolean> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/is_VIP_or_TAP`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static async getIsPreviousKiMember(): Promise<boolean> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/is_previous_KI_member`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns Consent Success
     * @throws ApiError
     */
    public static async getConsents(): Promise<Array<Consent>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/contacts/consents`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async createMemberConsent(
requestBody?: MemberConsent,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/contacts/create_member_consent`,
            body: requestBody,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async withdrawConsent(
requestBody?: MemberConsent,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/contacts/withdraw_member_consent`,
            body: requestBody,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

}